import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  GetApplicationsDocument,
  ProfileType,
  useCreateProfileMutation,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import gql from "graphql-tag";

gql`
  mutation createProfile($input: CreateProfileInputType!) {
    createProfile(input: $input) {
      ...ProfileParts
    }
  }
`;

export default function () {
  const mutation = useCreateProfileMutation({});

  function call(applicationId: string, profile: ProfileType) {
    mutation.mutate(
      {
        input: {
          name: profile.name,
          description: profile.description ?? "",
          applicationId: applicationId,
          roleIds:
            profile.roles?.flatMap((element) => element?.roleId ?? []) ?? [],
        },
      },
      {
        refetchQueries: [{ query: GetApplicationsDocument }],
        update: (cache, { data: update }) => {
          const parentQuery: QueryOptions<
            GetApplicationQueryVariables,
            GetApplicationQuery
          > = {
            query: GetApplicationDocument,
            variables: {
              applicationId: applicationId,
            },
          };
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              application: {
                ...cachedData?.application,
                profiles: [
                  ...(cachedData?.application?.profiles ?? []),
                  { ...update?.createProfile },
                ],
              },
            },
          });
        },
      }
    );
  }
  return {
    ...mutation,
    call,
  };
}
